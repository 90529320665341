/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Copy, Form, H3, Justify, Layout, MetricsBoard, Row, Text } from 'tea-component';
import { useFetchChainDetail, useFetchChainGeneral, useFetchCMCAndSDKDownload } from '../../common/apis/chains/hooks';
import { ChainDetailContext } from './chain-detail';
import { ChainReConfigModal } from './chain-re-config';
import { useGotoClick } from '../../utils/hooks';
import { splitUrl, Consensus } from 'src/utils/common';

const { Content } = Layout;

export default function ChainOverview() {
  const chainMode = splitUrl(location.search).get('chainMode');
  const isPublic = chainMode === 'public';
  const { chainId } = useContext(ChainDetailContext);
  const { chain, run: fetchChainGeneral } = useFetchChainGeneral();
  const { chain: chainDetail, run: fetchChainDetail } = useFetchChainDetail();
  const gotoAuth = useGotoClick(`./overview/auth?chainMode=${chainMode}`);

  useEffect(() => {
    fetchChainGeneral(chainId as string);
    fetchChainDetail(chainId as string);
  }, []);

  const [reConfigModalVisible, setReConfigModalVisible] = useState(false);
  const handleReConfigClick = useCallback(() => {
    setReConfigModalVisible(true);
  }, []);

  const handleReConfigClose = useCallback(() => {
    setReConfigModalVisible(false);
  }, []);

  const { fetchCMCAndSDKConfig } = useFetchCMCAndSDKDownload();
  // 下载链配置
  const handleDownloadClick = useCallback(async () => {
    await fetchCMCAndSDKConfig(chainId || '');
  }, []);

  return (
    <Content>
      <Content.Header title="区块链管理/区块链概览" />
      <Content.Body full>
        <Card>
          <Card.Body>
            <H3>关键指标</H3>
            <Row showSplitLine>
              <Col>
                <MetricsBoard title="累计交易数" value={chain?.TxNum.toLocaleString()} />
              </Col>
              <Col>
                <MetricsBoard title="最新区块高度" value={chain?.BlockHeight.toLocaleString()} />
              </Col>
              <Col>
                <MetricsBoard title="链上节点" value={chain?.NodeNum} unit={'个'} />
              </Col>
              <Col>
                <MetricsBoard title="链上组织数" value={chain?.OrgNum} unit={'个'} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Justify
              left={<H3>区块链信息</H3>}
              right={
                <>
                  {!isPublic && (
                    <Button type={'primary'} onClick={gotoAuth}>
                      链权限管理
                    </Button>
                  )}
                  <Button type={'primary'} onClick={handleReConfigClick}>
                    修改链配置
                  </Button>
                  <Button type={'primary'} onClick={handleDownloadClick}>
                    {isPublic ? '下载SDK配置' : '下载链配置'}
                  </Button>
                </>
              }
            />
            <Form>
              <Form.Item label="区块链ID">
                <Text reset theme={'text'}>
                  {chainDetail?.ChainId}
                  {chainDetail?.ChainId && (
                    <span className={'tea-ml-1n'}>
                      <Copy text={chainDetail.ChainId} />
                    </span>
                  )}
                </Text>
              </Form.Item>
              <Form.Item label="区块链名称">
                <Text reset theme={'text'}>
                  {chainDetail?.ChainName}
                </Text>
              </Form.Item>
              <Form.Item label="区块链版本">
                <Text reset theme={'text'}>
                  {chainDetail?.Version}
                </Text>
              </Form.Item>
              <Form.Item label="配置版本">
                <Text reset theme={'text'}>
                  {chainDetail?.Sequence}
                </Text>
              </Form.Item>
              <Form.Item label="账户模式">
                <Text reset theme={'text'}>
                  {chainDetail?.ChainMode}
                </Text>
              </Form.Item>
              <Form.Item label="共识策略">
                <Text reset theme={'text'}>
                  {Consensus[chainDetail?.Consensus || 0]}
                </Text>
              </Form.Item>
              <Form.Item label="区块最大容量">
                <Text reset theme={'text'}>
                  {chainDetail?.BlockTxCapacity}笔交易
                </Text>
              </Form.Item>
              <Form.Item label="交易过期时长">
                <Text reset theme={'text'}>
                  {chainDetail?.TxTimeout}s
                </Text>
              </Form.Item>
              <Form.Item label="出块间隔">
                <Text reset theme={'text'}>
                  {chainDetail?.BlockInterval}ms
                </Text>
              </Form.Item>
            </Form>
            {chainDetail && (
              <ChainReConfigModal
                chainDetail={chainDetail}
                visible={reConfigModalVisible}
                onClose={handleReConfigClose}
                onSubmit={() => fetchChainDetail(chainId as string)}
              />
            )}
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  );
}
