/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React from 'react';
import { Bubble, Icon, Text } from 'tea-component';

const GLOSSARY_INFO: { [index: string]: string } = {
  节点地址:
    '此处展示的节点地址为P2P地址，用于区块链节点间相互进行连接。如您需要通过SDK等其他客户端与节点进行交互，则需要使用RPC地址，此前在新建区块链-链参数配置处由您自行配置。如未自定义修改，默认节点的RPC端口为P2P端口+1000，例如：P2P地址（127.0.0.1:11301）、RPC地址（127.0.0.1:12301）',
  账户地址: '该账户的公钥通过算法生成的地址，用于标识该账户在链上的唯一身份。',
  '公钥账户(Public Key)':
    '通过公私钥及公钥账户地址标识用户在链上的唯一身份。该模式下对账户和节点的没有准入限制，任何一个账户都可以自由的加入或者退出该区块链。',
  '证书账户（PermissionedWithCert）': '基于数字证书标识链账户的角色，并基于角色管理账户的权限。',
  组织证书:
    '组织证书将作为准入该区块链的凭证，被添加到链配置文件中，并作为节点证书和用户证书的上级CA，为节点和用户颁发证书。',
  节点证书: '节点证书由所属组织ca颁发，作为节点的身份凭证，在区块链网络通讯中验证节点身份有效性。',
  用户证书: '用户证书由所属组织ca颁发，对上链交易进行签名，并根据签名信息验证交易的有效性。',
  区块链ID: '区块链ID用于标识链的唯一身份，不可重复。',
  区块最大容量: '此处设置每个区块单次最大可容纳多少笔交易。例10000笔',
  出块间隔: '此处设置相连两个区块出块的间隔时长。例：1000ms',
  交易过期时长: '若交易长时间未被打包上链，则会触发过期机制，过期的交易将作废，此处设置交易的过期时长，例：600s',
  共识策略:
    '长安链目前支持Solo、Raft、TBFT、HotStuff等几种共识，其中Solo共识主要用于测试，Raft适用不需要考虑恶意节点且需要性能较高的场景，TBFT和HotStuff适用需要考虑恶意节点的场景。',
  合约名称: '合约名称用于标识链的唯一身份，不可重复。',
  虚拟机类型:
    '虚拟机用于为智能合约提供执行环境，执行成功后，会将合约执行结果上链。目前支持Wasmer、EVM、WXVM、GASM、Native等虚拟机。',
  '额外信息(选填)': '如果您的智能合约在首次部署的时候，需要初始化一些参数，可在此处添加。',
  调用方法: '请填写正确的调用方法，防止上链失败。如果您需要经常调用，建议在部署合约的时先配置好对应的方法。',
  参数: '请根据合约调用方法，填写正确的入参，防止上链失败。',
  节点ID: '节点ID为该节点在P2P网络中的ID。',
  账本同步类型:
    'FULL为全账本同步，该节点将保存全量的链上账本。SPV模式只同步区块头信息，Light模式将同步区块头信息和指定的交易信息。',
  '报错日志采集服务（可选项）': '当您在使用链时如果链运行异常，本服务将采集错误日志，方便查看错误原因和修复错误。',
  监控开关: '如果您打开本服务，则在部署链的同时，会自动部署错误日志监控服务，采集报错的日志信息。',
  是否参加长安链改进计划:
    '如果您选择参与长安链改进计划，您部署的链在运行过程中所产生的错误日志信息，将自动上报给长安链官方，方便官方在后续的版本中持续优化项目。',
  管理平台地址:
    '如果您需要在管理平台上查看错误日志信息，则需要填写管理平台的部署地址，以便于错误日志采集服务将日志信息推送给管理平台。',
  '合约调用方法(选填)':
    '建议在部署合约的时先配置之后需要经常调用的合约方法，如果一个方法有多个入参请用逗号分隔。例：key1,key2,key3',
  部署方式:
    '如果是单机部署请指定节点最终会被部署的端口，并确保端口未被占用。\n' +
    '如果是多机部署请指定节点最终会被部署的IP和端口，并确保机器间网络通畅，端口未被占用。\n' +
    '其中RPC接口用于节点与应用间进行通讯，P2P接口用户节点间进行通讯。\n' +
    '部署时注意不能使用127.0.0.1作为IP地址。',
  链账户模式: '此处可选择要创建的区块链的账户模式，其中证书模式一般适用于准入型区块链，公钥模式适用于开放型区块链。',
};

/**
 * 对于系统中的名词增加解释提示
 */
const GlossaryGuide = ({ title }: { title: string }) => (
  <>
    <Text className={'tea-mr-1n'}>{title}</Text>
    {GLOSSARY_INFO[title] && (
      <Bubble content={<div className={'text-pre-line'}>{GLOSSARY_INFO[title]}</div>}>
        <Icon type="info" />
      </Bubble>
    )}
  </>
);

export default GlossaryGuide;
