/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { AxiosResponse } from 'axios';
import { ChainConsensus, ListResponse, Response, ResponseOk } from '../../interface';
import { callApi } from '../../api';
import {
  AuthOrg,
  AuthOrgRequest,
  AuthRole,
  CertUser,
  Chain,
  ChainAuth,
  ChainAuthRequest,
  ChainCreateRequest,
  ChainDeleteRequest,
  ChainDetail,
  ChainGeneral,
  ChainMode,
  ChainReConfigRequest,
  ChainSubscribeConfig,
  ChainSubscribeRequest,
  ContractForInvoke,
  ContractFreezeRequest,
  ContractInstallRequest,
  ContractManage,
  ContractManageListRequest,
  ContractRuntimeType,
  ContractUpgradeRequest,
  GetCertUserListParam,
  IChainBrowserBlockDetail,
  IChainBrowserBlockDetailRequest,
  IChainBrowserBlockItem,
  IChainBrowserContractDetail,
  IChainBrowserContractDetailRequest,
  IChainBrowserContractItem,
  IChainBrowserTableRequest,
  IChainBrowserTxDetail,
  IChainBrowserTxDetailRequest,
  IChainBrowserTxItem,
  IChainContractDetail,
  IChainNodeDetail,
  IChainNodeDetailRequest,
  IChainNodeItem,
  IChainNodeListRequest,
  IChainOrgItem,
  IChainOrgListRequest,
  IHomePageSearch,
  IHomePageSearchRequest,
  InvokeRecord,
  InvokeRecordListRequest,
  InvokeRecordRequest,
  VoteDetail,
  VoteManage,
  VoteRequest,
  VotManageListRequest,
  GetAdminResData,
  ReSubChainRequest,
} from './interface';
import { CertNode, CertNodeRequest, CertOrg, ChainAlgorithm, MapValueAsType } from '../certificates/interface';

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E9%93%BE%E5%88%97%E8%A1%A8.md
 * GetChainList
 */
export function fetchChainList(): Promise<AxiosResponse<ListResponse<Chain>>> {
  return callApi('GetChainList');
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%88%9B%E5%BB%BA%E9%93%BE.md
 * AddChain
 */
export function fetchChainCreate(params: ChainCreateRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('AddChain', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%88%A0%E9%99%A4%E9%93%BE.md
 */
export function fetchChainDelete(params: ChainDeleteRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('DeleteChain', params);
}
/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E9%87%8D%E6%96%B0%E8%AE%A2%E9%98%85%E9%93%BE.md
 */
export function fetchReSubChain(params: ReSubChainRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ResubscribeChain', params);
}
/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8B%E8%BD%BD%E9%93%BE%E9%85%8D%E7%BD%AE.md
 * DownloadChainConfig
 */
export function fetchChainDownload(chainId: string): Promise<AxiosResponse<Response<string>>> {
  return callApi('DownloadChainConfig', {
    ChainId: chainId,
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8B%E8%BD%BD%E9%93%BEcmc%E9%85%8D%E7%BD%AE.md
 * DownloadSdkConfig
 */
export function fetchCMCAndSDKDownload(chainId: string): Promise<AxiosResponse<Blob>> {
  return callApi(
    'DownloadSdkConfig',
    {
      ChainId: chainId,
    },
    { responseType: 'blob' },
  );
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%8E%B7%E5%8F%96%E5%85%B1%E8%AF%86%E7%AE%97%E6%B3%95%E5%88%97%E8%A1%A8.md
 * GetConsensusList
 * @param ChainMode
 */
export function fetchChainConsensusList(params: {
  ChainMode: string;
}): Promise<AxiosResponse<ListResponse<ChainConsensus>>> {
  return callApi('GetConsensusList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%AF%81%E4%B9%A6%E7%BB%84%E7%BB%87%E5%88%97%E8%A1%A8.md
 * GetCertOrgList
 * @param chainId
 * @param algorithm
 */
export function fetchCertOrgList(
  chainId?: string,
  algorithm?: MapValueAsType<typeof ChainAlgorithm>,
  nodeRole?: 0 | 1,
): Promise<AxiosResponse<ListResponse<CertOrg>>> {
  return callApi('GetCertOrgList', {
    ChainId: chainId ?? '',
    Algorithm: algorithm,
    NodeRole: nodeRole,
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%AF%81%E4%B9%A6%E8%8A%82%E7%82%B9%E5%88%97%E8%A1%A8.md
 * @param OrgId ChainId NodeRole
 * or
 * @param ChainId NodeRole ChainMode
 */
export function fetchCertNodeList(params: CertNodeRequest): Promise<AxiosResponse<ListResponse<CertNode>>> {
  return callApi('GetCertNodeList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%AE%A2%E9%98%85%E9%93%BE.md
 * @param params
 * SubscribeChain
 */
export function fetchChainSubscribe(params: ChainSubscribeRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('SubscribeChain', params);
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     console.log(params);
  //     resolve({
  //       data: {
  //         Response: {
  //           RequestId: 'fa11a5f1-f45c-42bb-bbd2-a5aa684f7c1c',
  //           Data: {
  //             Status: 'OK',
  //           },
  //         },
  //       },
  //       status: 200,
  //       statusText: '',
  //       headers: {},
  //       config: {},
  //     });
  //   }, 100);
  // });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%AF%81%E4%B9%A6%E7%94%A8%E6%88%B7%E5%88%97%E8%A1%A8.md
 * GetCertUserList
 * @param orgId
 */
export function fetchCertUserList(params?: GetCertUserListParam): Promise<AxiosResponse<ListResponse<CertUser>>> {
  return callApi('GetCertUserList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E6%A6%82%E8%A7%88%E7%BB%9F%E8%AE%A1.md
 * GeneralData
 */
export function fetchChainGeneral(chainId: string): Promise<AxiosResponse<Response<ChainGeneral>>> {
  return callApi('GeneralData', {
    ChainId: chainId,
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E6%A6%82%E8%A7%88%E9%93%BE%E8%AF%A6%E6%83%85.md
 * GetChainDetail
 */
export function fetchChainDetail(chainId: string): Promise<AxiosResponse<Response<ChainDetail>>> {
  return callApi('GetChainDetail', {
    ChainId: chainId,
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E4%BF%AE%E6%94%B9%E9%93%BE%E9%85%8D%E7%BD%AE.md
 * ModifyChainConfig
 */
export function fetchChainReConfig(params: ChainReConfigRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ModifyChainConfig', params);
}

/**
 * https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%8E%B7%E5%8F%96%E8%AE%A2%E9%98%85%E9%85%8D%E7%BD%AE.md
 * @param params
 */
export function fetchChainSubscribeConfig(
  params: Pick<Chain, 'ChainId'>,
): Promise<AxiosResponse<Response<ChainSubscribeConfig>>> {
  return callApi('GetSubscribeConfig', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E4%BF%AE%E6%94%B9%E9%93%BE%E6%9D%83%E9%99%90.md
 * ModifyChainAuth
 */
export function fetchChainAuth(params: ChainAuthRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ModifyChainAuth', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E5%8C%BA%E5%9D%97%E5%88%97%E8%A1%A8.md
 * GetBlockList 获取区块列表
 */
export function fetchChainBrowserBlockList(
  params: IChainBrowserTableRequest,
): Promise<AxiosResponse<ListResponse<IChainBrowserBlockItem>>> {
  return callApi('GetBlockList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E5%8C%BA%E5%9D%97%E8%AF%A6%E6%83%85.md
 * GetBlockDetail 获取区块详情
 */
export function fetchChainBrowserBlockDetail(
  params: IChainBrowserBlockDetailRequest,
): Promise<AxiosResponse<Response<IChainBrowserBlockDetail>>> {
  return callApi('GetBlockDetail', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E4%BA%A4%E6%98%93%E5%88%97%E8%A1%A8.md
 * GetTxList 获取交易列表
 */
export function fetchChainBrowserTxList(
  params: IChainBrowserTableRequest,
): Promise<AxiosResponse<ListResponse<IChainBrowserTxItem>>> {
  return callApi('GetTxList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E4%BA%A4%E6%98%93%E8%AF%A6%E6%83%85.md
 * GetTxDetail 获取交易详情
 */
export function fetchChainBrowserTxDetail(
  params: IChainBrowserTxDetailRequest,
): Promise<AxiosResponse<Response<IChainBrowserTxDetail>>> {
  return callApi('GetTxDetail', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E5%90%88%E7%BA%A6%E5%88%97%E8%A1%A8.md
 * GetContractList 获取合约列表
 */
export function fetchChainBrowerContractList(
  params: IChainBrowserTableRequest,
): Promise<AxiosResponse<ListResponse<IChainBrowserContractItem>>> {
  return callApi('GetContractList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8A%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E5%88%97%E8%A1%A8.md
 * GetContractList 获取合约列表-上链专用
 */
export function fetchInvokeChainContractList(chainId: string): Promise<AxiosResponse<ListResponse<ContractForInvoke>>> {
  return callApi('GetInvokeContractList', { ChainId: chainId });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E5%90%88%E7%BA%A6%E8%AF%A6%E6%83%85.md
 * GetContractDetail 获取合约详情
 */
export function fetchChainBrowserContractDetail(
  params: IChainBrowserContractDetailRequest,
): Promise<AxiosResponse<Response<IChainBrowserContractDetail>>> {
  return callApi('GetContractDetail', params);
}

/**
 * 合约详情
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E8%AF%A6%E6%83%85.md
 */
export function fetchChainContractDetail(params: {
  Id: ContractForInvoke['ContractId'];
}): Promise<AxiosResponse<Response<IChainContractDetail>>> {
  return callApi('ContractDetail', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E7%BC%96%E8%BE%91%E5%90%88%E7%BA%A6.md
 */
export function ModifyChainContractDetail(
  params: {
    Id: ContractForInvoke['ContractId'];
  } & Pick<ContractInstallRequest, 'Methods'>,
): Promise<AxiosResponse<Response<IChainContractDetail>>> {
  return callApi('ModifyContract', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%8A%82%E7%82%B9%E7%AE%A1%E7%90%86/%E8%8A%82%E7%82%B9%E5%88%97%E8%A1%A8.md
 * @description GetNodeList 获取节点列表
 */
export function fetchChainNodeList(
  params: IChainNodeListRequest,
): Promise<AxiosResponse<ListResponse<IChainNodeItem>>> {
  return callApi('GetNodeList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%8A%82%E7%82%B9%E7%AE%A1%E7%90%86/%E8%8A%82%E7%82%B9%E8%AF%A6%E6%83%85.md
 * @description GetNodeDetail 获取节点详情
 */
export function fetchChainNodeDetail(
  params: IChainNodeDetailRequest,
): Promise<AxiosResponse<Response<IChainNodeDetail>>> {
  return callApi('GetNodeDetail', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E7%BB%84%E7%BB%87%E7%AE%A1%E7%90%86/%E7%BB%84%E7%BB%87%E5%88%97%E8%A1%A8.md
 * @description GetOrgList 获取组织列表
 */
export function fetchChainOrgList(params: IChainOrgListRequest): Promise<AxiosResponse<ListResponse<IChainOrgItem>>> {
  return callApi('GetOrgList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E9%93%BE%E7%BB%84%E7%BB%87%E5%88%97%E8%A1%A8.md
 * @param params
 * GetAuthOrgList
 */
export function fetchAuthOrgList(params: AuthOrgRequest): Promise<AxiosResponse<ListResponse<AuthOrg>>> {
  return callApi('GetAuthOrgList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E9%93%BE%E8%A7%92%E8%89%B2%E5%88%97%E8%A1%A8.md
 * GetAuthRoleList
 * @param params
 */
export function fetchAuthRoleList(params: AuthOrgRequest): Promise<AxiosResponse<ListResponse<AuthRole>>> {
  return callApi('GetAuthRoleList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86%E5%88%97%E8%A1%A8.md
 * GetContractManageList
 * TODO 后端VoteStatus/Status字段当前缺少搜索支持
 */
export function fetchContractManageList(
  params: ContractManageListRequest,
): Promise<AxiosResponse<ListResponse<ContractManage>>> {
  return callApi('GetContractManageList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E9%94%80%E6%AF%81%E5%90%88%E7%BA%A6.md
 * RevokeContract
 * @param params
 */
export function fetchContractRevoke(params: ContractFreezeRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('RevokeContract', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E9%83%A8%E7%BD%B2%E5%90%88%E7%BA%A6.md
 * InstallContract
 */
export function fetchContractInstall(params: ContractInstallRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('InstallContract', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E5%8D%87%E7%BA%A7%E5%90%88%E7%BA%A6.md
 * @param params
 */
export function fetchContractUpgrade(params: ContractUpgradeRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('UpgradeContract', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E8%8E%B7%E5%8F%96%E5%90%88%E7%BA%A6%E7%B1%BB%E5%9E%8B%E5%88%97%E8%A1%A8.md
 * GetRuntimeTypeList
 */
export function fetchContractRuntimeTypeList(): Promise<AxiosResponse<ListResponse<ContractRuntimeType>>> {
  return callApi('GetRuntimeTypeList');
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E5%86%BB%E7%BB%93%E5%90%88%E7%BA%A6.md
 * FreezeContract
 */
export function fetchContractFreeze(params: ContractFreezeRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('FreezeContract', params);
}

/**
 * https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%90%88%E7%BA%A6%E7%AE%A1%E7%90%86/%E8%A7%A3%E5%86%BB%E5%90%88%E7%BA%A6.md
 * UnFreezeContract
 */
export function fetchContractUnFreeze(params: ContractFreezeRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('UnFreezeContract', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8A%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8A%E9%93%BE%E4%BA%A4%E6%98%93%E5%88%97%E8%A1%A8.md
 * GetInvokeRecordList
 * @param params
 */
export function fetchInvokeRecordList(
  params: InvokeRecordListRequest,
): Promise<AxiosResponse<ListResponse<InvokeRecord>>> {
  return callApi('GetInvokeRecordList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8A%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8F%91%E8%B5%B7%E4%B8%8A%E9%93%BE.md
 * InvokeContract
 * @param params
 */
export function fetchInvokeChain(params: InvokeRecordRequest): Promise<AxiosResponse<ResponseOk>> {
  return callApi('InvokeContract', params);
}

/*
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E4%B8%8A%E9%93%BE%E7%AE%A1%E7%90%86/%E9%87%8D%E6%96%B0%E4%B8%8A%E9%93%BE.md
 *
 */
export function fetchReInvokeChain(params: { InvokeRecordId: number }): Promise<AxiosResponse<ResponseOk>> {
  return callApi('ReInvokeContract', params);
}

/**
 * https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8%E5%88%97%E8%A1%A8.md
 * GetVoteManageList
 * @param params
 */
export function fetchChainVoteManageList(
  params: VotManageListRequest,
): Promise<AxiosResponse<ListResponse<VoteManage>>> {
  return callApi('GetVoteManageList', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8.md
 * Vote
 * @param params
 */
export function fetchChainVote(params: VoteRequest): Promise<AxiosResponse<{ Status: string }>> {
  return callApi('Vote', params);
}

/**
 * https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8%E7%AE%A1%E7%90%86/%E6%8A%95%E7%A5%A8%E8%AF%A6%E6%83%85.md
 * GetVoteDetail
 * @param params
 */
export function fetchChainVoteDetail(params: { VoteId: number }): Promise<AxiosResponse<Response<VoteDetail>>> {
  return callApi('GetVoteDetail', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%B5%8F%E8%A7%88%E5%99%A8/%E9%A6%96%E9%A1%B5%E6%A3%80%E7%B4%A2.md
 * @description HomePageSearch 首页检索
 */
export function fetchHomePageSearch(params: IHomePageSearchRequest): Promise<AxiosResponse<Response<IHomePageSearch>>> {
  return callApi('HomePageSearch', params);
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.2.0/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E5%8C%BA%E5%9D%97%E9%93%BE%E6%A6%82%E8%A7%88/%E9%93%BE%E6%9D%83%E9%99%90%E5%88%97%E8%A1%A8.md
 * GetAuthList
 * @param chainId
 */
export function fetchChainAuthList(chainId: string): Promise<AxiosResponse<ListResponse<ChainAuth>>> {
  return callApi('GetAuthList', {
    ChainId: chainId,
  });
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E8%8E%B7%E5%8F%96%E8%B4%A6%E6%88%B7%E6%A8%A1%E5%BC%8F.md
 * GetChainModes
 * @param
 */
export function fetchChainModes(): Promise<AxiosResponse<Response<ChainMode[]>>> {
  return callApi('GetChainModes');
}

/**
 * @see https://git.code.tencent.com/ChainMaker/documents/management-doc/blob/v2.3.0_sdy/interface/%E5%8C%BA%E5%9D%97%E9%93%BE%E7%AE%A1%E7%90%86/%E7%AE%A1%E7%90%86%E5%91%98%E5%88%97%E8%A1%A8.md
 * @param params
 * 获取链管理员列表列表
 * GetAdminList
 */
export function GetAdminList(
  ChainId: string | null | undefined,
): Promise<AxiosResponse<ListResponse<GetAdminResData>>> {
  return callApi(
    'GetAdminList',
    ChainId
      ? {
          ChainId,
        }
      : {},
  );
}
