/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import { ControllerFieldState } from 'react-hook-form';
import { FormControlProps } from 'tea-component/lib/form/FormControl';

export function getStatus({
  fieldState,
  isSubmitted,
  isValidating,
}: {
  fieldState: ControllerFieldState;
  isSubmitted: boolean;
  isValidating: boolean;
}): FormControlProps['status'] {
  if (isValidating) {
    return 'validating';
  }
  if (!fieldState.isDirty && !isSubmitted) {
    return undefined;
  }
  return fieldState.invalid ? 'error' : 'success';
}

export class Validator {
  /**
   * 针对区块最大容量，交易过期时长，出块间隔，当前底链没限制最大值
   */
  static maxNumber = 99_999_999;

  /**
   * 交易过期时长
   */
  static minTxTimeout = 600;

  /*
   * 出块间隔，单位毫秒
   */
  static minBlockInterval = 1;

  static minBlockTxCapacity = 10;

  static validateName(name: string): string | undefined {
    if (!name || !name.length) {
      return '请输入姓名';
    }
    if (name.length > 10) {
      return '超过长度不允许输入';
    }
    const expEN = /^[A-Za-z]{1,10}$/;
    const expZH = /^[\u4e00-\u9fa5]{1,10}$/;
    if (expEN.test(name) || expZH.test(name)) {
      return undefined;
    }
    return '请输入纯字母或汉字';
  }

  static validateAccount(account: string): string | undefined {
    if (!account || !account.length) {
      return '请输入账号';
    }
    const exp = /^[A-Za-z][A-Za-z0-9]{0,19}$/;
    if (!exp.test(account)) {
      return '请输入20位以内字母和数字组合，不能以数字开头';
    }
    return undefined;
  }

  static validatePassword(password: string): string | undefined {
    if (!password || !password.length) {
      return '请输入密码';
    }
    const exp = /^[A-Za-z0-9]{6,16}$/;
    if (!exp.test(password)) {
      return '请输入6-16位字母和数字组合';
    }
    return undefined;
  }

  static validateOrgId(orgId: string, type?: 'input' | 'select'): string | undefined {
    if (type === 'select' && !orgId) {
      return '请选择组织';
    }
    if (!orgId || !orgId.length) {
      return '请输入组织ID';
    }
    const exp = /^[\w\-.]{1,100}$/;
    if (!exp.test(orgId)) {
      return '请输入100位以内字母、数字、中横线、下划线、小数点组合';
    }
    return undefined;
  }

  static AuthName(AuthName: string): string | undefined {
    if (!AuthName || !AuthName.length) {
      return '请输入权限资源名';
    }
    const exp = /^[A-Za-z_-]{1,100}$/;
    if (!exp.test(AuthName)) {
      return '请输入100位以内字母、中横线、下划线组合';
    }
    return undefined;
  }

  static RemarkName(RemarkName: string): string | undefined {
    if (!RemarkName || !RemarkName.length) {
      return '请输入账户备注名';
    }

    const exp = /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/;
    const expNumber = /^[0-9]{1,30}$/;
    if (!exp.test(RemarkName) || expNumber.test(RemarkName)) {
      return '请输入20位以内字母、数字、汉字组合，不能为纯数字';
    }
    return undefined;
  }

  static validateOrgName(orgName: string): string | undefined {
    if (!orgName || !orgName.length) {
      return '请输入组织名称';
    }
    const exp = /^[A-Za-z0-9\u4e00-\u9fa5]{1,30}$/;
    const expNumber = /^[0-9]{1,30}$/;
    if (!exp.test(orgName) || expNumber.test(orgName)) {
      return '请输入30位以内字母、数字、汉字组合，不能为纯数字';
    }
    return undefined;
  }

  static validateCountry(country: string): string | undefined {
    if (!country || !country.length) {
      return '请输入国家';
    }
    const exp = /^[A-Za-z\u4e00-\u9fa5]{1,20}$/;
    if (!exp.test(country)) {
      return '请输入20位以内字母、汉字组合';
    }
    return undefined;
  }

  static validateLocality(locality: string): string | undefined {
    if (!locality || !locality.length) {
      return '请输入地区';
    }
    const exp = /^[A-Za-z\u4e00-\u9fa5]{1,20}$/;
    if (!exp.test(locality)) {
      return '请输入20位以内字母、汉字组合';
    }
    return undefined;
  }

  static validateProvince(province: string): string | undefined {
    if (!province || !province.length) {
      return '请输入省份';
    }
    const exp = /^[A-Za-z\u4e00-\u9fa5]{1,20}$/;
    if (!exp.test(province)) {
      return '请输入20位以内字母、汉字组合';
    }
    return undefined;
  }

  static validateNodeName(nodeName: string): string | undefined {
    if (!nodeName || !nodeName.length) {
      return '请输入节点名称';
    }
    const exp = /^[A-Za-z0-9\u4e00-\u9fa5]{1,30}$/;
    // const exp = /^[A-Za-z0-9]{1,30}$/;
    const expNumber = /^[0-9]{1,30}$/;
    if (!exp.test(nodeName) || expNumber.test(nodeName)) {
      return '请输入30位以内字母、数字、汉字组合，不能为纯数字';
    }
    return undefined;
  }

  static validateNodeIP(nodeIP: string): string | undefined {
    if (!nodeIP || !nodeIP.length) {
      return '请输入节点IP';
    }
    const exp =
      /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5]):([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/;
    if (!exp.test(nodeIP)) {
      return '请输入正确的节点IP和端口';
    }
    return undefined;
  }

  static validateIP(nodeIP: string): string | undefined {
    if (!nodeIP || !nodeIP.length) {
      return '请输入节点IP';
    }
    const exp = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
    if (!exp.test(nodeIP)) {
      return '请输入正确的节点IP';
    }
    return undefined;
  }

  static validatePort(input: string): string | undefined {
    if (!input || !input.length) {
      return '请输入端口';
    }
    if (input.match(/[^\d]/) || Number(input) > 65535 || Number(input) < 0) {
      return '请输入正确的端口';
    }
    return undefined;
  }

  static validateUserName(userName: string): string | undefined {
    if (!userName || !userName.length) {
      return '请输入用户名称';
    }
    const exp = /^[A-Za-z0-9\u4e00-\u9fa5]{1,30}$/;
    // const exp = /^[A-Za-z0-9]{1,30}$/;
    const expNumber = /^[0-9]{1,30}$/;
    if (!exp.test(userName) || expNumber.test(userName)) {
      return '请输入30位以内字母、数字、汉字组合，不能为纯数字';
    }
    return undefined;
  }

  static validateChainId(chainId: string): string | undefined {
    if (!chainId || !chainId.length) {
      return '请输入区块链ID';
    }
    const exp = /^[\w\-.]{1,30}$/;
    if (!exp.test(chainId)) {
      return '请输入30位以内字母、数字、中横线、下划线、小数点组合';
    }
    return undefined;
  }

  static validateChainName(chainName: string): string | undefined {
    if (!chainName || !chainName.length) {
      return '请输入区块链名称';
    }
    const exp = /^[A-Za-z0-9\u4e00-\u9fa5]{1,20}$/;
    const expNumber = /^[0-9]{1,20}$/;
    if (!exp.test(chainName) || expNumber.test(chainName)) {
      return '请输入20位以内字母、数字、汉字组合，不能为纯数字';
    }
    return undefined;
  }

  static validateTLSHost(tlsHost: string): string | undefined {
    if (!tlsHost || !tlsHost.length) {
      return '请输入TLS_Host';
    }
    const exp = /^[\w]{1,20}$/;
    if (!exp.test(tlsHost)) {
      return '请输入20位以内字母、数字、下划线组合';
    }
    return undefined;
  }

  static validateContractFuncName(contractFuncName: string): string | undefined {
    if (!contractFuncName || !contractFuncName.length) {
      return '请输入合约调用方法名';
    }
    const exp = /^[\w]{1,30}$/;
    if (!exp.test(contractFuncName)) {
      return '请输入30位以内字母、数字、下划线组合';
    }
    return undefined;
  }

  static validateContractName(value: string) {
    if (!value || !value.length) {
      return '请输入合约名称';
    }
    const exp = /^[\w]{1,30}$/;
    if (!exp.test(value)) {
      return '请输入30位以内字母、数字、下划线组合';
    }
    return undefined;
  }

  static validateContractVersion(contractVersion: string): string | undefined {
    if (!contractVersion || !contractVersion.length) {
      return '请输入合约版本';
    }
    const exp = /^[A-Za-z0-9][A-Za-z0-9.]{0,39}$/;
    if (!exp.test(contractVersion)) {
      return '请输入40位以内字母、数字、小数点组合，不能以小数点开头';
    }
    return undefined;
  }

  static validateUpgradeContractVersion =
    (preVersion: string | undefined) =>
    (contractVersion: string): string | undefined => {
      if (contractVersion === preVersion) {
        return '合约版本号不能跟当前版本号相同';
      }
      return Validator.validateContractVersion(contractVersion);
    };

  static validateCaptcha(value: string): string | undefined {
    if (!value || !value.length) {
      return '请输入验证码';
    }
    if (value.length !== 4) {
      return '验证码为4位';
    }
    return undefined;
  }

  static validateReason(value: string | null) {
    if (value?.length && value.length > 200) {
      return '超过长度200';
    }
    return undefined;
  }
}

export default {
  getStatus,
};
