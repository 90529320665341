/*
 *
 *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  SPDX-License-Identifier: Apache-2.0
 *
 */
import React, { useEffect } from 'react';
import { Button, Input, Modal, Form } from 'tea-component';
import { useFetchCertDetail, useFetchDownloadCert } from 'src/common/apis/certificates/hooks';

const { TextArea } = Input;

interface ICertDetailModalParam {
  visible: boolean;
  CertId: number | undefined;
  close: () => void;
}

export function DetailPublicModal({ visible, CertId, close }: ICertDetailModalParam) {
  const { privateKey, publicKey, fetch } = useFetchCertDetail();
  const { fetch: fetchDownload } = useFetchDownloadCert();

  useEffect(() => {
    if (visible && CertId !== undefined) {
      fetch({ CertId, CertUse: 0 });
    }
  }, [visible, CertId]);

  const download = async () => {
    if (CertId !== undefined) {
      await fetchDownload({ CertId, CertUse: 4 });
      await fetchDownload({ CertId, CertUse: 5 });
      close();
    }
  };
  return (
    <Modal caption="查看账户" visible={visible} onClose={close}>
      <Modal.Body>
        <Form layout="vertical" style={{ width: '100%' }}>
          <Form.Item label="账户私钥">
            <TextArea readOnly size="full" rows={8} value={privateKey} />
          </Form.Item>
          <Form.Item label="账户公钥">
            <TextArea readOnly size="full" rows={8} value={publicKey} />
          </Form.Item>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" disabled={!privateKey && !publicKey} onClick={download}>
          下载
        </Button>
        <Button onClick={close}>取消</Button>
      </Modal.Footer>
    </Modal>
  );
}
